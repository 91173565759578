import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../axios";
import forgetImg from "../../assets/images/forget-password.png";
import "./ResetPassword.css";
const OrganizationResetPassword = () => {
  const { token } = useParams(); // Get token from URL
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState(""); // Success or Error
  const [loading, setLoading] = useState(false); // For button loading
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match.");
      setMessageType("danger");
      return;
    }

    setLoading(true);
    try {
      const response = await axiosInstance.post(
        "/api/organization/reset-password",
        {
          token,
          newPassword,
        }
      );

      setMessage(response.data.message);
      setMessageType(response.data.status === "success" ? "success" : "danger");

      if (response.data.status === "success") {
        setTimeout(() => navigate("/organizationlogin2"), 3000); // Redirect to organization login page
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
      setMessageType("danger");
    }
    setLoading(false);
  };
  return (
    <>
      <div className="container-fluid p-0 vh-100 bg-light">
        <div className="row no-gutters h-100">
          <div className="col-5 p-0 image-container h-100 d-flex justify-content-start">
            <img src={forgetImg} className="img-fluid" alt="forget-password" />
          </div>
          <div className="col-6 mx-auto">
            <div className="d-flex justify-content-center align-items-center vh-100 form-container">
              <div className="p-4">
                <h2 className="text-center mb-3">
                  Reset Organization Password
                </h2>

                {message && (
                  <div className={`alert alert-${messageType}`}>{message}</div>
                )}

                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label">
                      New Password{" "}
                      <span className="text-danger fw-bold">*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control py-3"
                      placeholder="Enter new password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">
                      Confirm Password{" "}
                      <span className="text-danger fw-bold">*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control py-3"
                      placeholder="Confirm new password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary w-100 py-3"
                    disabled={loading}
                  >
                    {loading ? "Resetting..." : "Reset Password"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationResetPassword;
