import Home from "../pages/Home";
import { Login } from "../pages/user/Login";
import Signup from "../pages/user/Signup";
import Signup2 from "../pages/user/Signup2";
import { CandidadteHome } from "../pages/user/CandidateHome";
import { UserProfile } from "../pages/user/Userprofile";
import { CompanyProfile } from "../pages/user/CompanyProfile";
import { UserChatHistory } from "../pages/user/UserChatHistory";
import { UserMessages } from "../pages/user/UserMessages";
import { AdminLogin } from "../pages/admin/Login";
import { OrganizationLogin } from "../pages/organization/Login";
import { OrganizationSignup } from "../pages/organization/Signup";
import { OrganizationDashboard } from "../pages/organization/OrganizationDashboard";
import { AdminDashboard } from "../pages/admin/AdminDashboard";
import { ViewEvent } from "../pages/user/ViewEvent";
import { CompanyProfile2 } from "../components/user/ViewEvent/CompanyProfile2";
import { UserProtected } from "../components/protected/UserProtected";
import { AdminProtected } from "../components/protected/AdminProtected";
import { OrgProtected } from "../components/protected/OrgProtected";
import { AllOngoingEventsPage } from "../pages/user/AllOngoingEventsPage";
import { AllUpcomingEventsPage } from "../pages/user/AllUpcomingEventsPage";
import { AllCompaniesPage } from "../pages/user/AllCompaniesPage";
import { ViewEventOrg } from "../pages/organization/ViewEventOrg";
import { UserEventProfile } from "../pages/organization/UserEventProfile";
import { OrganizationUserLogin } from "../pages/organizationUser/Login";
import OrganizationUserLogin2 from "../pages/organizationUser/Login2.jsx";
import { OrganizationUserDashboard } from "../pages/organizationUser/OrganizationUserDashboard";
import { CreateOrganizationUser } from "../components/organization/organizationusers/OrganizationUserCreate";
import ResetPassword from "../pages/user/ResetPassword.js";
import Login2 from "../pages/user/Login2.jsx";
import OrganizationLogin2 from "../pages/organization/Login2.jsx";
import OrganizationResetPassword from "../pages/organization/ResetPassword.jsx";
import OrganizationSignup2 from "../pages/organization/Signup2.jsx";
import AdminLogin2 from "../pages/admin/Login2.jsx";
import { AdminSignup } from "../pages/admin/Signup.js";

const routes = [
  { path: "/", element: <Home /> },
  // { path: "/login", element: <Login /> },
  { path: "/login2", element: <Login2 /> },
  // { path: "/signup", element: <Signup /> },
  { path: "/signup2", element: <Signup2 /> },
  { path: "/reset-password/:token", element: <ResetPassword /> },
  {
    path: "/candidatehome",
    element: (
      <UserProtected>
        <CandidadteHome />
      </UserProtected>
    ),
  },
  {
    path: "/userprofile",
    element: (
      <UserProtected>
        <UserProfile />
      </UserProtected>
    ),
  },
  {
    path: "/companyprofile",
    element: (
      <UserProtected>
        <CompanyProfile />
      </UserProtected>
    ),
  },
  {
    path: "/companyeventprofile",
    element: (
      <UserProtected>
        <CompanyProfile2 />
      </UserProtected>
    ),
  },
  {
    path: "/userchathistory",
    element: (
      <UserProtected>
        <UserChatHistory />
      </UserProtected>
    ),
  },
  {
    path: "/usermessages",
    element: (
      <UserProtected>
        <UserMessages />
      </UserProtected>
    ),
  },
  {
    path: "/Event",
    element: (
      <UserProtected>
        <ViewEvent />
      </UserProtected>
    ),
  },
  {
    path: "/allOngoingEvents",
    element: (
      <UserProtected>
        <AllOngoingEventsPage />
      </UserProtected>
    ),
  },
  {
    path: "/allUpcomingEvents",
    element: (
      <UserProtected>
        <AllUpcomingEventsPage />
      </UserProtected>
    ),
  },
  {
    path: "/allCompanies",
    element: (
      <UserProtected>
        <AllCompaniesPage />
      </UserProtected>
    ),
  },
  // { path: "/organizationlogin", element: <OrganizationLogin /> },
  { path: "/organizationlogin2", element: <OrganizationLogin2 /> },
  // { path: "/organizationsignup", element: <OrganizationSignup /> },
  { path: "/organizationsignup2", element: <OrganizationSignup2 /> },
  {
    path: "/organization/reset-password/:token",
    element: <OrganizationResetPassword />,
  },
  {
    path: "/organizationdashboard",
    element: (
      <OrgProtected>
        <OrganizationDashboard />
      </OrgProtected>
    ),
  },
  {
    path: "/eventOrg",
    element: (
      <OrgProtected>
        <ViewEventOrg />
      </OrgProtected>
    ),
  },
  {
    path: "/user-org-Profile",
    element: (
      <OrgProtected>
        <UserEventProfile />
      </OrgProtected>
    ),
  },
  // { path: "/organizationUserlogin", element: <OrganizationUserLogin /> },
  { path: "/organizationUserLogin2", element: <OrganizationUserLogin2 /> },
  {
    path: "/organizationUserDashboard",
    element: <OrganizationUserDashboard />,
  },
  { path: "/CreateOrganizationUser", element: <CreateOrganizationUser /> },
  { path: "/adminlogin", element: <AdminLogin2 /> },
  { path: "/adminsignup", element: <AdminSignup /> },
  // { path: "/adminlogin2", element: <AdminLogin2 /> },
  {
    path: "/admindashboard",
    element: (
      <AdminProtected>
        <AdminDashboard />
      </AdminProtected>
    ),
  },
];

export default routes;
