import profleImage from "../../assets/images/profileDefault.png"
import banner from "../../assets/images/contact.jpg"
import "../../assets/css/style.css"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

export const AdminSignup = () => {
    let navigate = useNavigate()
    return(
        <div className="grid-container">
            <div className="item">
                <div style={{marginTop: "2%", textAlign: "center", height: "97vh"}}>
                    <h1 style={{fontWeight: "600", color: "#235183"}}>ADMIN SIGNUP</h1>
                    <form onSubmit={()=>{}}>
                        <div style={{position: "relative", marginTop: "2%"}}>
                            <div style={{position: "absolute", left: "54%", top: "70%"}}>
                                <FontAwesomeIcon icon={faPlus} size="2x"/>
                            </div>
                            <img src={profleImage} style={{width: "160px", height: "140px", borderRadius: "50%"}} alt="" />
                        </div>
                        <div className="grid-container-2" style={{marginLeft: "5%", marginRight: "5%"}}>

                            <div style={{margin: "3%"}}>
                                <label style={{color: "#8f8f8f"}}>FIRST NAME</label><br />
                                <input style={{border: "none", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "70%"}}
                                    type="text" />
                            </div>
                            <div style={{margin: "3%"}}>
                                <label style={{color: "#8f8f8f"}}>LAST NAME</label><br />
                                <input style={{border: "none", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "70%"}}
                                    type="text" />
                            </div>
                            <div style={{margin: "3%"}}>
                                <label style={{color: "#8f8f8f"}}>EMAIL</label><br />
                                <input style={{border: "none", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "70%"}}
                                    type="text"/>
                            </div>
                            <div style={{margin: "3%"}}>
                                <label style={{color: "#8f8f8f"}}>PHONE</label><br />
                                <input style={{border: "none", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "70%"}}
                                    type="text" />
                            </div>
                            <div style={{margin: "3%"}}>
                                <label style={{color: "#8f8f8f"}}>PASSWORD</label><br />
                                <input style={{border: "none", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "70%"}}
                                    type="password" />
                            </div>
                            <div style={{margin: "3%"}}>
                                <label style={{color: "#8f8f8f"}}>CONFIRM PASSWORD</label><br />
                                <input style={{border: "none", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "70%"}}
                                    type="password" />
                            </div>
                        </div>

                        <div style={{margin: "3%"}}>
                            <label style={{color: "#8f8f8f"}}>SOCIAL MEDIA LINKS</label><br />
                        </div>


                        <div className="grid-container-3" style={{marginLeft: "8%", marginRight: "8%"}}>
                            <div style={{margin: "1%"}}>
                                <input style={{border: "none", borderBottom: "1px solid #8f8f8f" , padding: "1%", width: "75%"}}
                                    type="password" />
                            </div>
                            <div style={{margin: "1%"}}>
                                {/* <label style="color: #8f8f8f">SOCIAL MEDIA LINKS</label><br /> --> */}
                                <input style={{border: "none", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "70%"}}
                                    type="password" />
                            </div>
                            <div style={{margin: "1%"}}>
                                {/* <!-- <label style="color: #8f8f8f">SOCIAL MEDIA LINKS</label><br /> --> */}
                                <input style={{border: "none", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "70%"}}
                                    type="password" />
                            </div>
                        </div>

                        <div style={{marginTop: "4%"}}>
                            <button className="signIn-fill"
                                style={{width: "45%", padding: "1.5%", backgroundColor: "#235183", border: "2px solid #235183", color: "white", borderRadius: "2rem"}}>SIGN
                                UP</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="item">
                <div className="login-cover"
                    style={{backgroundImage:
                    `linear-gradient(90deg, rgba(0, 0, 0, 0.4826681014202556) 0%, rgba(0, 0, 0, 0.7878781854538691) 100%, rgba(0, 0, 0, 1) 100%),
                    url(${banner})`, height: "100vh", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
                    <div>
                        <h1 style={{color: "white", paddingTop: "15%"}}>ALREADY A MEMBER?</h1>
                        <p style={{color: "white", fontSize: "18px", marginTop: "2%"}}>Login</p>
                        <div style={{marginTop: "80%"}}>
                            <button onClick={() => navigate("/")} className="signUp-trans"
                                style={{width: "70%", padding: "2%", border: "2px solid white", color: "white", backgroundColor: "transparent", borderRadius: "2rem"}}>Sign
                                In</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}