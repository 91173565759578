import UserForm from "../../components/SignUp/UserForm.jsx";
// import NavBar3 from "../../components/user/Navbar3";
import SignupImg from "../../assets/images/signupImg.png";

const Signup2 = () => {
  return (
    <>
      <div className="container-fluid pe-0 m-0">
        <div className="row w-100 pe-0">
          <div className="col-12 col-lg-7 py-5">
            <UserForm />
          </div>
          <div className="d-sm-none d-lg-block col-lg-5 ">
            <img src={SignupImg} className="img-fluid h-100" alt="signup" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup2;
