import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./Form.css";
import { axiosInstance } from "../../axios/index.js";
import {
  linkValidation,
  nameValidation,
  phoneValidation,
  validateEmail,
} from "../../functions/index.js";

const OrganizationForm = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    trigger,
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      name: "",
      email: "",
      contact: "",
      password: "",
      confirmPassword: "",
      socialLink: "",
      description: "",
      coverImage: null,
    },
  });

  const password = watch("password");

  const handleFileValidation = (file, allowedTypes, maxSize, fieldName) => {
    if (!file || !allowedTypes.includes(file.type) || file.size > maxSize) {
      let errorMessage;
      if (!file) {
        errorMessage = `Please select an ${fieldName} file.`;
      } else if (!allowedTypes.includes(file.type)) {
        errorMessage = `Invalid ${fieldName} file type. Allowed types are ${allowedTypes.join(", ")}.`;
      } else {
        errorMessage = `${fieldName} file size should be under ${maxSize / (1024 * 1024)}MB.`;
      }
      return errorMessage;
    }
    return "";
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const imageErrorMessage = handleFileValidation(
      file,
      ["image/jpeg", "image/png", "image/gif"],
      5 * 1024 * 1024,
      "coverImage"
    );
    if (imageErrorMessage) {
      setError("coverImage", { type: "manual", message: imageErrorMessage });
      setValue("coverImage", null);
    } else {
      try {
        const formData = new FormData();
        formData.append("imageFile", file); // Match /imageUpload expectation
        const res = await axiosInstance.post("/imageUpload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        const imageUrl = res.data.message; // S3 URL from server
        setValue("coverImage", imageUrl);
        clearErrors("coverImage");
      } catch (error) {
        console.error("API Error:", error.response?.data || error.message);
        setError("coverImage", { type: "manual", message: "Error uploading image." });
      }
    }
    trigger("coverImage");
  };

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      console.log("Form Data:", data);
      formData.append("name", data.name);
      formData.append("email", data.email);
      formData.append("contact", data.contact);
      formData.append("password", data.password);
      formData.append("socialLink", data.socialLink);
      formData.append("description", data.description || "");
      formData.append("coverImage", data.coverImage || ""); // S3 URL

      const response = await axiosInstance.post("/api/organization/signUp", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.data.status === "success" && response.data.message === "Organization created successfully") {
        console.log("Organization created successfully!");
        reset();
        navigate("/");
      } else {
        setError("root", { type: "manual", message: response.data.message });
      }
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      setError("root", { type: "manual", message: "Signup failed. Please try again." });
    }
  };

  return (
    <div className="col-11 col-lg-8 mx-auto">
      <div className="d-flex justify-content-center mb-3">
        <h1 className="title fs-1">Organization Sign Up</h1>
      </div>
      {errors.root && <div className="alert alert-danger">{errors.root.message}</div>}
      <form
        noValidate
        className="row g-3 needs-validation"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* Name */}
        <div className="col-md-12">
          <div className="form-floating mb-3">
            <input
              type="text"
              className={`form-control ${errors.name ? "is-invalid" : ""}`}
              id="name"
              placeholder="name"
              {...register("name", {
                required: "Please enter your organization name.",
                validate: nameValidation,
              })}
            />
            <label htmlFor="name" className="text-capitalize">
              Organization Name <span className="text-danger fw-bold ps-1">*</span>
            </label>
            <div className="invalid-feedback">{errors.name?.message}</div>
          </div>
        </div>

        {/* Email */}
        <div className="col-md-12">
          <div className="form-floating mb-3">
            <input
              type="email"
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
              id="email"
              placeholder="name@example.com"
              {...register("email", {
                required: "Please enter your email.",
                validate: validateEmail,
              })}
            />
            <label htmlFor="email">Email<span className="text-danger fw-bold ps-1">*</span></label>
            <div className="invalid-feedback">{errors.email?.message}</div>
          </div>
        </div>

        {/* Contact */}
        <div className="col-md-12">
          <div className="form-floating mb-3">
            <input
              type="tel"
              className={`form-control ${errors.contact ? "is-invalid" : ""}`}
              id="contact"
              placeholder="+00-000-000000"
              {...register("contact", {
                required: "Please enter your contact number.",
                validate: phoneValidation,
              })}
            />
            <label htmlFor="contact">Contact Number<span className="text-danger fw-bold ps-1">*</span></label>
            <div className="invalid-feedback">{errors.contact?.message}</div>
          </div>
        </div>

        {/* Password */}
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input
              type="password"
              className={`form-control ${errors.password ? "is-invalid" : ""}`}
              id="password"
              placeholder="Password"
              {...register("password", {
                required: "Please enter your password.",
                minLength: { value: 8, message: "Password must be at least 8 characters." },
              })}
            />
            <label htmlFor="password">Password<span className="text-danger fw-bold ps-1">*</span></label>
            <div className="invalid-feedback">{errors.password?.message}</div>
          </div>
        </div>

        {/* Confirm Password */}
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input
              type="password"
              className={`form-control ${errors.confirmPassword ? "is-invalid" : ""}`}
              id="confirmPassword"
              placeholder="Confirm Password"
              {...register("confirmPassword", {
                required: "Please confirm your password.",
                validate: (value) => value === password || "Passwords do not match.",
              })}
            />
            <label htmlFor="confirmPassword">Confirm Password<span className="text-danger fw-bold ps-1">*</span></label>
            <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
          </div>
        </div>

        {/* Social Link */}
        <div className="col-md-12">
          <div className="input-group mb-3">
            <span className="input-group-text" id="socialLink-addon">
              <i className="bi bi-twitter"></i>
            </span>
            <div className="form-floating">
              <input
                type="url"
                className={`form-control ${errors.socialLink ? "is-invalid" : ""}`}
                id="socialLink"
                placeholder="Social Link"
                {...register("socialLink", {
                  required: "Please enter your Twitter link.",
                  validate: linkValidation,
                })}
              />
              <label htmlFor="socialLink">Twitter<span className="text-danger fw-bold ps-1">*</span></label>
              <div className="invalid-feedback">{errors.socialLink?.message}</div>
            </div>
          </div>
        </div>

        {/* Description */}
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Please Enter Your Organization's Description
          </label>
          <textarea
            className={`form-control ${errors.description ? "is-invalid" : ""}`}
            id="description"
            rows="3"
            {...register("description")}
          ></textarea>
          <div className="invalid-feedback">{errors.description?.message}</div>
        </div>

        {/* Cover Image */}
        <div className="mb-3">
          <label htmlFor="coverImage" className="form-label">
            Please Upload Your Organization Image (JPG, PNG, or GIF Max 5MB)<span className="text-danger fw-bold ps-1">*</span>
          </label>
          <input
            type="file"
            className={`form-control ${errors.coverImage ? "is-invalid" : ""}`}
            id="coverImage"
            accept="image/jpeg, image/png, image/gif"
            onChange={handleImageChange}
          />
          <div className="invalid-feedback">{errors.coverImage?.message}</div>
        </div>

        {/* Submit Button */}
        <div className="d-grid gap-2">
          <button
            className="py-3 btn btn-primary"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Sign Up"}
          </button>
        </div>
      </form>

      <div className="text-center mt-3">
        <p>
          Already a member?{" "}
          <Link to="/organizationlogin2" className="text-decoration-none fw-semibold">
            Login
          </Link>
        </p>
      </div>
    </div>
  );
};

export default OrganizationForm;