import React from "react";
import banner from "../../assets/images/contact.jpg";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";

const NavBar3 = () => {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark"
      style={{ backgroundColor: "#235183" }}
    >
      <div className="container-fluid">
        <Link className="navbar-brand mx-5" to="/">
          <img src={logo} style={{ height: "80px", width: "110px" }} alt="" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mb-2 mb-lg-0 d-flex justify-content-end w-100 mx-5">
            <li className="nav-item">
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/adminlogin" className="nav-link">
                Admin
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/organizationlogin2">
                Organization
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/login2">
                User
              </Link>
            </li>


          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar3;