import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import loginImg from "../../assets/images/login-form.jpg";
import "./Login2.css"; // Custom CSS for styling
import { axiosInstance } from "../../axios";
import { validateEmail } from "../../functions";
import { UserContext } from "../../context/AuthProvider";
import { Modal, Button } from "react-bootstrap"; // For forgot password modal

const OrganizationLogin2 = () => {
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  // State for login form
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  // State for forgot password
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [showForgotPasswordPopup, setShowForgotPasswordPopup] = useState(false);

  useEffect(() => {
    const savedEmail = localStorage.getItem("eemail");
    if (savedEmail) {
      setEmail(savedEmail);
      setRememberMe(true);
    }
  }, []);

  // Handle login form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    setIsDisabled(true);
    try {
      const res = await axiosInstance.post("/api/organization/signIn", {
        email,
        password,
      });

      if (res.data.status === "success") {
        localStorage.setItem("orgToken", res.data.token);
        localStorage.setItem("email", res.data.Data.email);
        localStorage.setItem("orgID", res.data.Data._id);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("orgRole", "1");

        if (rememberMe) {
          localStorage.setItem("rememberEmail", email);
        } else {
          localStorage.removeItem("rememberEmail");
        }

        setUser({
          email: res.data.Data.email,
          token: res.data.token,
          userId: res.data.Data._id,
          isLoggedIn: true,
          role: "user",
        });

        navigate("/organizationdashboard");
      } else {
        alert(res.data.message);
      }
    } catch (err) {
      console.error(err);
      alert("Server Error: Please try again later.");
    } finally {
      setIsDisabled(false);
    }
  };

  // Handle forgot password
  const handleForgotPassword = async () => {
    if (!validateEmail(forgotPasswordEmail)) {
      alert("Please enter a valid email address.");
      return;
    }

    setIsDisabled(true);
    try {
      const res = await axiosInstance.post("api/organization/forgot-password", {
        email: forgotPasswordEmail,
      });
      if (res.data.status === "success") {
        alert("Password reset link sent to your email.");
        setShowForgotPasswordPopup(false); // Close the modal
      } else {
        alert(res.data.message);
      }
    } catch (err) {
      console.error(err);
      alert("Something went wrong. Please try again.");
    } finally {
      setIsDisabled(false);
    }
  };
  return (
    <div className="container-fluid vh-100 p-0 m-0 overflow-hidden">
      <div className="row g-0 h-100">
        {/* Left Side - Image */}
        <div className="col-md-6 d-none d-md-block">
          <img
            src={loginImg}
            className="img-fluid h-100 w-100"
            alt="Login"
            style={{ objectFit: "cover" }}
          />
        </div>

        {/* Right Side - Login Form */}
        <div className="col-md-6 d-flex align-items-start justify-content-center pt-4 mt-5 ps-3 pe-3">
          <div className="w-100" style={{ maxWidth: "400px" }}>
            <h1 className="text-center mb-4">Welcome back!</h1>
            <p className="text-center mb-4">
              Enter to get unlimited access to data & information.
            </p>

            {/* Login Form */}
            <form onSubmit={handleSubmit}>
              {/* Email Input */}
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email <span className="text-danger fw-bold">*</span>
                </label>
                <input
                  type="email"
                  className="form-control py-3"
                  id="email"
                  placeholder="Enter your mail address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              {/* Password Input */}
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password<span className="text-danger fw-bold"> *</span>
                </label>
                <input
                  type="password"
                  className="form-control py-3"
                  id="password"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>

              {/* Remember Me & Forgot Password */}
              <div className="d-flex justify-content-between align-items-center mb-4">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="rememberMe"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="rememberMe">
                    Remember me
                  </label>
                </div>
                <button
                  type="button"
                  className="btn btn-link text-decoration-none p-0"
                  onClick={() => setShowForgotPasswordPopup(true)}
                >
                  Forgot your password?
                </button>
              </div>

              {/* Login Button */}
              <button
                type="submit"
                className="btn btn-primary w-100 py-3 mb-3"
                disabled={isDisabled}
              >
                {isDisabled ? "Logging in..." : "Login"}
              </button>

              {/* Sign Up Link */}
              <div className="text-center">
                <p className="d-inline mb-0 pe-0">
                  Don't have an account?&nbsp;
                </p>
                <Link
                  to="/organizationsignup2"
                  className="btn btn-link text-decoration-none ps-0 ms-0"
                >
                  Sign Up
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Forgot Password Modal */}
      <Modal
        show={showForgotPasswordPopup}
        onHide={() => setShowForgotPasswordPopup(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="email"
            placeholder="Enter your email"
            value={forgotPasswordEmail}
            onChange={(e) => setForgotPasswordEmail(e.target.value)}
            className="form-control"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowForgotPasswordPopup(false)}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleForgotPassword}
            disabled={isDisabled}
          >
            {isDisabled ? "Sending..." : "Send Reset Link"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OrganizationLogin2;
