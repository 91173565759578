import { Link, useNavigate } from "react-router-dom";
import adminImg from "../../assets/images/adminLogin.jpg";
import { useEffect, useState, useContext } from "react";
import { axiosInstance } from "../../axios";
import { UserContext } from "../../context/AuthProvider";

const AdminLogin2 = () => {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  // Email Validation
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  const validateEmail = (e) => {
    const value = e.target.value;
    setEmail(value);
    setIsTouched(true);
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setIsValid(regex.test(value));
  };

  // Password Validation
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordTouched, setIsPasswordTouched] = useState(false);

  const validatePassword = (e) => {
    const value = e.target.value;
    setPassword(value);
    setIsPasswordTouched(true);
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    setIsPasswordValid(regex.test(value));
  };

  // Remember Me
  const [rememberMe, setRememberMe] = useState(false);

  // Error and Loading States
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  useEffect(() => {
    const savedEmail = localStorage.getItem("rememberedEmail");
    const savedPassword = localStorage.getItem("rememberedPassword");

    if (savedEmail && savedPassword) {
      setEmail(savedEmail);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  // Handle login form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Reset error state
    setShowError(false);
    setErrorMessage("");

    // Validate before API call
    if (!isValid || !isPasswordValid) {
      setErrorMessage("Please enter a valid email and password.");
      setShowError(true);
      return;
    }

    setIsLoading(true); // Set loading state to true

    try {
      // API call similar to old code
      const response = await axiosInstance.post("api/admin/login", {
        email: email,
        password: password,
      });

      if (response.data.status === "success") {
        // Save token and user details in localStorage
        localStorage.setItem("adminToken", response.data.token);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("adminRole", "1");

        // Update user context
        setUser((prevState) => ({
          ...prevState,
          email: response.data.Data.email,
          token: response.data.token,
          isLoggedIn: true,
          role: "admin",
        }));

        // Handle "Remember Me" feature
        if (rememberMe) {
          localStorage.setItem("rememberedEmail", email);
          localStorage.setItem("rememberedPassword", password);
        } else {
          localStorage.removeItem("rememberedEmail");
          localStorage.removeItem("rememberedPassword");
        }

        // Redirect to admin dashboard
        navigate("/admindashboard");
      } else if (response.data.status === "failed") {
        // Show error message based on API response
        setErrorMessage(response.data.message || "Login failed. Please try again.");
        setShowError(true);
      } else {
        // Unexpected response format
        setErrorMessage("Unexpected response from server. Please try again.");
        setShowError(true);
      }
    } catch (err) {
      // Handle network or other errors
      console.error(err.toJSON());
      const errorMsg = err.response?.data?.message || "Something went wrong. Please try again.";
      setErrorMessage(errorMsg);
      setShowError(true);
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  return (
    <>
      <div className="container-fluid p-0 m-0 vh-100 overflow-hidden">
        <div className="row h-100">
          <div className="col-6">
            <img src={adminImg} className="img-fluid" alt="" />
          </div>
          <div className="col-5 pt-5 mx-auto">
            <h1 className="text-center mt-5 fw-bold fs-1 mb-3">
              Welcome Back!
            </h1>
            <p className="text-center">
              Enter to get unlimited access to data and information
            </p>
            {/* Error Message Display */}
            {showError && (
              <div className="alert alert-danger text-center" role="alert">
                {errorMessage}
              </div>
            )}
            <form onSubmit={handleSubmit} className="row g-3 w-75 mx-auto">
              {/* Email Input */}
              <div className="col-md-12">
                <label htmlFor="validationServer01" className="form-label">
                  Email <span className="text-danger fw-bold">*</span>
                </label>
                <input
                  type="email"
                  className={`form-control py-3 ${
                    isTouched ? (isValid ? "is-valid" : "is-invalid") : ""
                  }`}
                  id="validationServer01"
                  placeholder="Enter your email"
                  required
                  value={email}
                  onChange={validateEmail}
                  onBlur={() => setIsTouched(true)}
                />
                {isTouched && !isValid && (
                  <div className="invalid-feedback">
                    Please enter a valid email.
                  </div>
                )}
              </div>

              {/* Password Input */}
              <div className="col-md-12">
                <label htmlFor="validationServer03" className="form-label">
                  Password
                  <span>
                    <span className="text-danger fw-bold ms-2">*</span>
                  </span>
                </label>
                <input
                  type="password"
                  className={`form-control py-3 ${
                    isPasswordTouched
                      ? isPasswordValid
                        ? "is-valid"
                        : "is-invalid"
                      : ""
                  }`}
                  id="validationServer03"
                  required
                  placeholder="Enter your password"
                  onChange={validatePassword}
                  onBlur={() => setIsPasswordTouched(true)}
                />
                {isPasswordTouched && !isPasswordValid && (
                  <div
                    id="validationServer03Feedback"
                    className="invalid-feedback"
                  >
                    Password must be at least 8 characters, include an uppercase
                    letter, a lowercase letter, and a number.
                  </div>
                )}
                {isPasswordTouched && isPasswordValid && (
                  <div className="valid-feedback">Looks good!</div>
                )}
              </div>

              {/* Remember Me */}
              <div className="col-12 d-flex justify-content-between">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultValue
                    id="flexCheckDefault"
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Remember me
                  </label>
                </div>
                <button
                  type="button"
                  className="btn btn-link text-decoration-none p-0"
                  // onClick={() => setShowForgotPasswordPopup(true)}
                >
                  Forgot your password?
                </button>
              </div>
              <div className="col-12">
                <div className="d-grid gap-2">
                  <button
                    className="btn btn-primary py-3"
                    type="submit"
                    disabled={isLoading} // Disable button during loading
                  >
                    {isLoading ? "Logging in..." : "Login"}
                  </button>
                </div>
              </div>
              <div className="col-12 text-center">
                <p>
                  Don't have an account?
                  <Link
                    to="/admin/signup"
                    className="text-decoration-none ms-2"
                  >
                    Signup
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLogin2;