import OrganizationForm from "../../components/SignUp/OrgForm.jsx";
import SignupImg from "../../assets/images/orgSignupImg.jpg";
const OrganizationSignup2 = () => {
  return (
    <>
      <div className="container-fluid p-3">
        <div className="row">
          <div className="col-12 col-lg-7 py-5">
            <OrganizationForm />
          </div>
          <div className="d-none d-lg-block col-lg-5 ">
            <img
              src={SignupImg}
              className="img-fluid h-100 d-block"
              alt="signup"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationSignup2;
