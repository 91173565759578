import axios from "axios";

const userToken = () => localStorage.getItem("userToken");
const orgToken = () => localStorage.getItem("orgToken");
const adminToken = () => localStorage.getItem("adminToken");
const orgUserToken = () => localStorage.getItem("orgUserToken");

const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://backend.tiptopcareerfair.com/"
    : "http://localhost:8080/";

export const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

// for User
export const axiosAuthInstance = axios.create({
  baseURL: baseURL,
  headers: {},
});
axiosAuthInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${userToken()}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// for Organization
export const axiosOrgAuthInstance = axios.create({
  baseURL: baseURL,
  headers: {
    // "access-control-allow-origin": "*",
  },
});
axiosOrgAuthInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${orgToken()}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// for Admin
export const axiosAdminAuthInstance = axios.create({
  baseURL: baseURL,
  headers: {
    // "access-control-allow-origin": "*",
  },
});
axiosAdminAuthInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${adminToken()}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// for Organization User
export const axiosOrgUserAuthInstance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    // "access-control-allow-origin": "*",
  },
});
axiosOrgUserAuthInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      "orgUserToken"
    )}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
