import { Link, useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { AiOutlineSearch} from "react-icons/ai";
import {
  MdOutlineSpaceDashboard,
  MdOutlineMiscellaneousServices,
} from "react-icons/md";
import { RiChatHistoryLine } from "react-icons/ri";
import { BsCheck2, BsFillCreditCard2FrontFill } from "react-icons/bs";
// import {BsChevronDown} from "react/icons/bs"
import { useState } from "react";
import { Dashboard } from "../../components/organization/dashboard/Dashboard";
import { Booth } from "../../components/organization/booth/Booth";
import { ChatHistory } from "../../components/organization/chathistory/ChatHistory";
import { RegisteredEvents } from "../../components/organization/events/RegisteredEvents";
import "../../assets/css/dashboard.css";
import { AllUpComingEvents } from "../../components/organization/dashboard/AllUpComingEvents";
import { AllOnGoingEvents } from "../../components/organization/dashboard/AllOnGoingEvents";
import { Popup } from "../../components/Popup";
import { OrganizationDetails } from "../../components/organization/profile/OrganizationDetails";
import { FaRegUser, FaUsers, FaUserPlus } from "react-icons/fa";
import { EditProfile } from "../../components/organization/profile/EditProfile";
import { OrganizationUsers } from "../../components/organization/organizationusers/OrganizationUsers";
import { CreateOrganizationUser } from "../../components/organization/organizationusers/OrganizationUserCreate";

export const OrganizationDashboard = () => {
  const [showComponent, setShowComponent] = useState("dashboard");
  const [pop, setPop] = useState(false);
  const [search, setSearch] = useState(""); //not implemented
  let Navigate = useNavigate()

  return (
    <>
      <div className="parentContainer">
        <div>
          <div>
            <h2
              style={{
                textAlign: "center",
                marginTop: "15%",
                fontSize: "28px",
                textTransform: "uppercase",
                color: "white",
                fontWeight: "700",
              }}
            >
              Virtual Career Fair
            </h2>
            <ul style={{ padding: 0, margin: "20% 0", textAlign: "left" }}>
              <li
                className={showComponent === "dashboard" ? "activate" : ""}
                onClick={() => {
                  setShowComponent("dashboard");
                }}
              >
                <Link>
                  <MdOutlineSpaceDashboard size={30} />
                  <span>DASHBOARD</span>
                </Link>
              </li>
              <li
                className={showComponent === "registered" ? "activate" : ""}
                onClick={() => {
                  setShowComponent("registered");
                }}
              >
                <Link>
                  <BsCheck2 size={30} />
                  <span>Registered In </span>
                </Link>
              </li>
              <li
                className={showComponent === "booth" ? "activate" : ""}
                onClick={() => {
                  setShowComponent("booth");
                }}
              >
                <Link>
                  <MdOutlineMiscellaneousServices size={30} />
                  <span>BOOTH</span>
                </Link>
              </li>
              <li
                className={showComponent === "chathistory" ? "activate" : ""}
                onClick={() => {
                  setShowComponent("chathistory");
                  // setDropDown(false);
                }}
              >
                <Link>
                  <RiChatHistoryLine size={30} />
                  <span>CHAT HISTORY</span>
                </Link>
              </li>
              <li
                className={showComponent === "profile" ? "activate" : ""}
                onClick={() => {
                  setShowComponent("profile");
                  // setDropDown(false);
                }}
              >
                <Link>
                  <FaRegUser size={30} />
                  <span>PROFILE</span>
                </Link>
              </li>
              <li
                className={showComponent === "users" ? "activate" : ""}
                onClick={() => {
                  setShowComponent("users");
                  // setDropDown(false);
                }}
              >
                <Link>
                  <FaUsers size={30} />
                  <span>USERS</span>
                </Link>
              </li>
              <li
                className={showComponent === "usercreate" ? "activate" : ""}
                onClick={() => {
                  setShowComponent("usercreate");
                  // setDropDown(false);
                }}
              >
                <Link>
                  <FaUserPlus size={30} />
                  <span>CREATE USERS</span>
                </Link>
              </li>
              <li>
                <Link to="/organizationlogin" onClick={()=>{
                    localStorage.clear()
                    Navigate('/organizationlogin')
                  }}>
                  <FiLogOut size={30} />
                  <span>LOGOUT</span>
                </Link>
              </li>
            </ul>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "98vh",
              width: "80%",
              borderRadius: "25px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                height: "13vh",
                alignItems: "center",
              }}
            >
              <h2 style={{ paddingLeft: "30px" }}>
                Welcome{" "}
                <span style={{ color: "#235183", fontWeight: "600" }}>{localStorage.getItem('name')}</span>
              </h2>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  paddingRight: "50px",
                }}
              >
                <div
                  className="border shadow"
                  style={{ borderRadius: "10px", padding: "0 8px" }}
                >
                  <button
                    style={{
                      height: "40px",
                      padding: "0 10px",
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    <AiOutlineSearch />
                  </button>
                  <input
                    style={{
                      height: "40px",
                      padding: "0 10px",
                      border: "none",
                      outline: "none",
                    }}
                    placeholder="Search"
                  />
                </div>
                <div style={{ paddingLeft: "30px" }}>
                  <h5 style={{ padding: 0, margin: 0 }}>{localStorage.getItem('name')}</h5>
                </div>
                <button
                  className="border shadow"
                  style={{
                    marginLeft: "30px",
                    padding: "5px 10px",
                    backgroundColor: "white",
                    borderRadius: "10px",
                  }}
                  onClick={()=>{
                    localStorage.clear()
                    Navigate('/organizationlogin')
                  }}
                >
                  <FiLogOut />
                </button>
              </div>
            </div>

            <div style={{ height: "84vh" }}>
              {showComponent === "dashboard" ? (
                <Dashboard
                  setShowComponent={setShowComponent}
                />
              ) : null}
              {showComponent === "allupcomingevents" ? (
                <AllUpComingEvents setShowComponent={setShowComponent} />
              ) : null}
              {showComponent === "allongoingevents" ? (
                <AllOnGoingEvents setShowComponent={setShowComponent} />
              ) : null}
              {showComponent === 'usercreate' ? <CreateOrganizationUser /> : null}
              {showComponent === "users" ? <OrganizationUsers /> : null}
              {showComponent === "registered" ? <RegisteredEvents /> : null}
              {showComponent === "booth" ? <Booth /> : null}
              {showComponent === "chathistory" ? <ChatHistory /> : null}
              {showComponent === "profile" ? <OrganizationDetails setShowComponent={setShowComponent}/> : null}
              {showComponent === "editProfile" ? <EditProfile setShowComponent={setShowComponent}/> : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
