import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./Form.css";
import { axiosInstance } from "../../axios/index.js";
import {
  linkValidation,
  nameValidation,
  phoneValidation,
  validateEmail,
} from "../../functions/index.js";

const UserForm = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    trigger,
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
      socialMedia1: "",
      socialMedia2: "",
      socialMedia3: "",
      imageFile: null,
      cvFile: null,
    },
  });

  const password = watch("password");

  const handleFileValidation = (file, allowedTypes, maxSize, fieldName) => {
    if (!file || !allowedTypes.includes(file.type) || file.size > maxSize) {
      console.log("File Validation:", { file, allowedTypes, maxSize });

      let errorMessage;
      if (!file) {
        errorMessage = `Please select an ${fieldName.replace(
          "File",
          ""
        )} file.`;
      } else if (!allowedTypes.includes(file.type)) {
        errorMessage = `Invalid ${fieldName.replace(
          "File",
          ""
        )} file type. Allowed types are ${allowedTypes.join(", ")}.`;
      } else {
        errorMessage = `${fieldName.replace(
          "File",
          ""
        )} file size should be under ${maxSize / (1024 * 1024)}MB.`;
      }
      return errorMessage;
    }
    return "";
  };

  // Image Upload
  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const imageErrorMessage = handleFileValidation(
      file,
      ["image/jpeg", "image/png", "image/gif"],
      5 * 1024 * 1024,
      "imageFile"
    );
    if (imageErrorMessage) {
      setError("imageFile", { type: "manual", message: imageErrorMessage });
      setValue("imageFile", null);
    } else {
      try {
        const formData = new FormData();
        console.log(formData);
        formData.append("imageFile", file);

        // Send the image to the API
        const res = await axiosInstance.post("/imageUpload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Upload Response:", res); // Debug response

        const imageUrl = res.data.message;
        setValue("imageFile", imageUrl);
        clearErrors("imageFile");
      } catch (error) {
        console.error("API Error:", error.response?.data || error.message);
        setError("imageFile", {
          type: "manual",
          message: "Error uploading image.",
        });
      }
    }
    trigger("imageFile");
  };

  // CV Upload
  // CV Upload Handler
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const cvErrorMessage = handleFileValidation(
      file,
      ["application/pdf", "application/msword"],
      2 * 1024 * 1024,
      "cvFile"
    );

    if (cvErrorMessage) {
      setError("cvFile", { type: "manual", message: cvErrorMessage });
      setValue("cvFile", null);
    } else {
      try {
        const formData = new FormData();
        formData.append("cvFile", file); // Append CV file to FormData

        // Send the CV file to the API
        const res = await axiosInstance.post("/cvUpload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("CV Upload Response:", res); // Debug response

        const cvUrl = res.data.message; // Assuming the API returns the URL
        setValue("cvFile", cvUrl); // Set the CV URL in the form
        clearErrors("cvFile");
      } catch (error) {
        console.error("API Error:", error.response?.data || error.message);
        setError("cvFile", {
          type: "manual",
          message: "Error uploading CV.",
        });
      }
    }
    trigger("cvFile");
  };

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      // *** Verify these values are correctly populated in 'data' ***
      console.log("Form Data:", data);
      // Append other form fields to FormData
      formData.append("firstName", data.firstName);
      formData.append("lastName", data.lastName);
      formData.append("email", data.email);
      formData.append("password", data.password);
      formData.append("phone", data.phone);
      // *** Verify these URLs are correct after file uploads ***
      console.log("Image URL:", data.imageFile);
      console.log("CV URL:", data.cvFile);
      // Append image and CV URLs to FormData
      formData.append("imageFile", data.imageFile); // AWS S3 URL
      formData.append("cvFile", data.cvFile); // AWS S3 URL
      console.log(formData);
      const response = await axiosInstance.post("/api/user", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status === "success") {
        console.log("User created successfully!");
        navigate("/"); // Redirect to home or login page
      }
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
    }
  };
  return (
    <div className="col-11 col-lg-8 mx-auto">
      <div className="d-flex justify-content-center mb-3">
        <h1 className="title fs-1">Sign Up</h1>
      </div>
      <form
        noValidate
        className="row g-3 needs-validation"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* First Name */}
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input
              type="text"
              className={`form-control ${errors.firstName ? "is-invalid" : ""}`}
              id="firstName"
              placeholder="First Name"
              {...register("firstName", {
                required: "Please enter your first name.",
                validate: nameValidation, // Use corrected function
              })}
            />
            <label htmlFor="firstName">
              First Name<span className="text-danger fw-bold ps-1">*</span>
            </label>
            <div className="invalid-feedback">
              {errors.firstName && errors.firstName.message}
            </div>
          </div>
        </div>

        {/* Last Name */}
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input
              type="text"
              className={`form-control ${errors.lastName ? "is-invalid" : ""}`}
              id="lastName"
              placeholder="Last Name"
              {...register("lastName", {
                required: "Please enter your last name.",
                validate: nameValidation,
              })}
            />
            <label htmlFor="lastName">
              Last Name<span className="text-danger fw-bold ps-1">*</span>
            </label>
            <div className="invalid-feedback">
              {errors.lastName && errors.lastName.message}
            </div>
          </div>
        </div>

        {/* Email */}
        <div className="col-md-12">
          <div className="form-floating mb-3">
            <input
              type="email"
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
              id="email"
              placeholder="name@example.com"
              {...register("email", {
                required: "Please enter your email.",
                validate: validateEmail,
              })}
            />
            <label htmlFor="email">
              Email<span className="text-danger fw-bold ps-1">*</span>
            </label>
            <div className="invalid-feedback">
              {errors.email && errors.email.message}
            </div>
          </div>
        </div>

        {/* Phone Number */}
        <div className="col-md-12">
          <div className="form-floating mb-3">
            <input
              type="tel"
              className={`form-control ${errors.phone ? "is-invalid" : ""}`}
              id="phone"
              placeholder="+00-000-000000"
              {...register("phone", {
                required: "Please enter your phone number.",
                validate: phoneValidation,
              })}
            />
            <label htmlFor="phone">
              Phone Number<span className="text-danger fw-bold ps-1">*</span>
            </label>
            <div className="invalid-feedback">
              {errors.phone && errors.phone.message}
            </div>
          </div>
        </div>

        {/* Password */}
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input
              type="password"
              className={`form-control ${errors.password ? "is-invalid" : ""}`}
              id="password"
              placeholder="Password"
              {...register("password", {
                required: "Please enter your password.",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters.",
                },
              })}
            />
            <label htmlFor="password">
              Password<span className="text-danger fw-bold ps-1">*</span>
            </label>
            <div className="invalid-feedback">
              {errors.password && errors.password.message}
            </div>
          </div>
        </div>

        {/* Confirm Password */}
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input
              type="password"
              className={`form-control ${
                errors.confirmPassword ? "is-invalid" : ""
              }`}
              id="confirmPassword"
              placeholder="Confirm Password"
              {...register("confirmPassword", {
                required: "Please confirm your password.",
                validate: (value) =>
                  value === password || "Passwords do not match.",
              })}
            />
            <label htmlFor="confirmPassword">
              Confirm Password
              <span className="text-danger fw-bold ps-1">*</span>
            </label>
            <div className="invalid-feedback">
              {errors.confirmPassword && errors.confirmPassword.message}
            </div>
          </div>
        </div>

        {/* Social Media Link 1 */}
        <div className="col-md-12">
          <div className="input-group mb-3">
            <span className="input-group-text" id="socialMedia1-addon">
              <i className="bi bi-linkedin"></i>
            </span>
            <div className="form-floating">
              <input
                type="url"
                className={`form-control ${
                  errors.socialMedia1 ? "is-invalid" : ""
                }`}
                id="socialMedia1"
                placeholder="Social Media Link 1"
                {...register("socialMedia1", {
                  validate: (value) =>
                    !value || linkValidation(value) || "Invalid URL format.",
                })}
              />
              <label htmlFor="socialMedia1">LinkedIn</label>
              <div className="invalid-feedback">
                {errors.socialMedia1 && errors.socialMedia1.message}
              </div>
            </div>
          </div>
        </div>

        {/* Social Media Link 2 */}
        <div className="col-md-12">
          <div className="input-group mb-3">
            <span className="input-group-text" id="socialMedia2-addon">
              <i className="bi bi-twitter"></i>
            </span>
            <div className="form-floating">
              <input
                type="url"
                className={`form-control ${
                  errors.socialMedia2 ? "is-invalid" : ""
                }`}
                id="socialMedia2"
                placeholder="Social Media Link 2"
                {...register("socialMedia2", {
                  validate: (value) =>
                    !value || linkValidation(value) || "Invalid URL format.",
                })}
              />
              <label htmlFor="socialMedia2">Twitter</label>
              <div className="invalid-feedback">
                {errors.socialMedia2 && errors.socialMedia2.message}
              </div>
            </div>
          </div>
        </div>

        {/* Social Media Link 3 */}
        <div className="col-md-12">
          <div className="input-group mb-3">
            <span className="input-group-text" id="socialMedia3-addon">
              <i className="bi bi-github"></i>
            </span>
            <div className="form-floating">
              <input
                type="url"
                className={`form-control ${
                  errors.socialMedia3 ? "is-invalid" : ""
                }`}
                id="socialMedia3"
                placeholder="Social Media Link 3"
                {...register("socialMedia3", {
                  validate: (value) =>
                    !value || linkValidation(value) || "Invalid URL format.",
                })}
              />
              <label htmlFor="socialMedia3">GitHub</label>
              <div className="invalid-feedback">
                {errors.socialMedia3 && errors.socialMedia3.message}
              </div>
            </div>
          </div>
        </div>

        {/* Image Upload */}
        <div className="mb-3">
          <label htmlFor="formImage" className="form-label">
            Please Upload Your Profile Image (JPG, PNG, or GIF Max 5MB)
            <span className="text-danger fw-bold ps-1">*</span>
          </label>
          <input
            type="file"
            className={`form-control ${errors.imageFile ? "is-invalid" : ""}`}
            id="formImage"
            accept="image/jpeg, image/png, image/gif"
            onChange={handleImageChange} // Use custom handler
          />
          <div className="invalid-feedback">
            {errors.imageFile && errors.imageFile.message}
          </div>
        </div>

        {/* CV Upload */}
        <div className="mb-3">
          <label htmlFor="formFile" className="form-label">
            Please Upload Your CV (PDF or Doc Max 2MB)
            <span className="text-danger fw-bold ps-1">*</span>
          </label>
          <input
            type="file"
            className={`form-control ${errors.cvFile ? "is-invalid" : ""}`}
            id="formFile"
            accept=".pdf,.doc,.docx"
            onChange={handleFileChange} // Use custom handler
          />
          <div className="invalid-feedback">
            {errors.cvFile && errors.cvFile.message}
          </div>
        </div>

        {/* Submit Button */}
        <div className="d-grid gap-2">
          <button
            className="py-3 btn btn-primary"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Sign Up"}
          </button>
        </div>
      </form>

      {/* Already a Member */}
      <div className="text-center mt-3">
        <p>
          Already a member?{" "}
          <Link to="/login2" className="text-decoration-none fw-semibold">
            Login
          </Link>
        </p>
      </div>
    </div>
  );
};

export default UserForm;
